import React from "react";

export default function Confidentiality() {
  return (
    <div>
      <div className="h4">Условия конфиденциальности</div>
      <div style={{ marginTop: "16px" }}>
        <div className="Body-3">Сбор и использование данных:</div>
        <div className="Body-2">
          Мы собираем личные данные, такие как имя, контактная информация и
          информация об использовании сайта, чтобы улучшить качество
          предоставляемых услуг и обеспечить соответствие с вашими
          потребностями.
        </div>
        <div className="Body-2">
          Мы не передаем ваши личные данные третьим лицам без вашего согласия,
          за исключением случаев, предусмотренных законом.
        </div>
        <div className="Body-3" style={{ marginTop: "8px" }}>
          Безопасность данных:
        </div>
        <div className="Body-2">
          Мы принимаем все необходимые меры для защиты ваших данных от
          несанкционированного доступа, изменения, раскрытия или уничтожения.
        </div>
        <div className="Body-2">
          Мы используем современные технологии и процедуры безопасности для
          обеспечения защиты ваших данных.
        </div>
        <div className="Body-3" style={{ marginTop: "8px" }}>
          Использование файлов cookie:
        </div>
        <div className="Body-2">
          Наш сайт использует файлы cookie для улучшения пользовательского опыта
          и аналитики. Вы можете настроить параметры файлов cookie в своем
          браузере.
        </div>
        <div className="Body-3" style={{ marginTop: "8px" }}>
          Ваши права:
        </div>
        <div className="Body-2">
          Вы имеете право на доступ к вашим личным данным, их исправление или
          удаление. Для этого свяжитесь с нами по указанному ниже контакту.
        </div>
        <div className="Body-3" style={{ marginTop: "8px" }}>
          Контактная информация:
        </div>
        <div className="Body-2">
          Если у вас есть вопросы по поводу нашей политики конфиденциальности,
          вы можете связаться с нами по номеру WhatsApp +79142701411.
        </div>
      </div>
    </div>
  );
}
